import React from "react"
import moment from "moment"

import RichText from "./rich-text"

const Concert = ({ date, title, description, link }) => {
  const now = new Date().getTime()
  if (Date.parse(date) < now) return null
  return (
    <>
      <dt>
        {moment(date.substring(0, 10), "YYYY-MM-DD").format("MMMM Do, YYYY")}
      </dt>
      <dd>
        <h3>{title}</h3>
        {description && <RichText source={description.description} />}
        {link && <a href={link}>Veranstaltungs-Website</a>}
      </dd>
    </>
  )
}

const Concerts = ({ entries }) => (
  <div id="concerts">
    <section>
      <h2>Events</h2>
      <dl>
        {entries.map(entry => (
          <Concert {...entry.node} />
        ))}
      </dl>
    </section>
  </div>
)

export default Concerts
