import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Concerts from "../components/concerts"
import Footer from "../components/footer"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Events" />
    <Concerts entries={data.allContentfulConcert.edges} />
    <Footer contacts={data.allContentfulContact.edges} />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query Events {
    allContentfulConcert(sort: { fields: date }) {
      edges {
        node {
          id
          date
          link
          title
          description {
            description
          }
        }
      }
    }
    allContentfulContact {
      edges {
        node {
          id
          title
          contact {
            contact
          }
        }
      }
    }
  }
`
